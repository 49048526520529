*{
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

:root {
  --primary-colour: rgb(3,46,46);
  --medium-colour: rgb(222,222,222);
  --bright-colour: rgb(83, 196, 196);
  --light-colour: rgb(197, 221, 221);
  --dark-colour: rgb(0,30,32);
  --inverse-colour: rgb(33, 169, 128);
}

::-webkit-scrollbar {
  width: 12px; /* Width of the entire scrollbar */
  height: 12px; /* Height of the scrollbar if it's horizontal */
}

/* The track (background) of the scrollbar */
::-webkit-scrollbar-track {
  background: var(--dark-colour);
}

/* The handle (scrollbar thumb) */
::-webkit-scrollbar-thumb {
  background: rgb(6, 88, 88);
  border-radius: 5px; /* Rounded corners */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(19, 124, 124);
}

h1 {
  font-family: "Lilita One", sans-serif;
}

.App {
  background: linear-gradient(0deg, rgba(0,30,32,1) 6%, rgba(3,46,46,1) 85%);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100dvh;
}

.box {
  width: 98%;
  margin-bottom: 20px;
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
  /*background-color: #ffffff1b;*/
  background: radial-gradient(circle, rgba(255,255,255,.1) 0%, rgba(255,255,255,.02) 100%);
  transition: all 0.5s ease-in-out;
  box-sizing: border-box;
  color: white;
}

.box:hover {
  box-shadow: 0px 0px 12px 5px rgba(0,0,0,0.3);
  background: radial-gradient(circle, rgba(255,255,255,.12) 0%, rgba(255,255,255,.03) 100%);
}

header{
  width: 100%;
}

a {
  text-decoration: none;
  color: white;
}

a:hover {
  text-decoration: none;
  color: white;
}

a:active {
  text-decoration: none;
  color: white;
}

a:visited{
  text-decoration: none;
  color: white;
}