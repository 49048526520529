#projects{
    display: inline-flex;
    justify-content: start;
    align-items: center;
}

#projects h2{
    color: white;
}

#touchScreenHover{
    position:relative;
    top: 1rem;
}

#activeProjectDetails {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    text-align: center;
    display: inline;
    align-items: center;
    justify-content: start;
    overflow: auto;
    padding: 1rem;
    box-sizing: border-box;
}

#activeProjectDetails p {
    text-align: center;
    transition: all 0.2s;
    position:relative;
    color: white;
}

#projectLinks {
    display: inline-flex;
}

.projectLink {
    margin: 2rem 1rem;
    background-color: var(--primary-colour);
    padding: 1rem;
    border-radius: 5px;
    font-weight: bold;
    transition: all 0.2s;
    position: relative;
    text-decoration: none;
}

.projectLink:hover {
    background-color: rgb(6, 83, 83);
    cursor: pointer;
    box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.3);
}

.projectWarning {
    margin-bottom: 1rem;
    font-weight: bold;
    padding: 0.5rem;
    border-radius: 1rem;
    background-color: var(--bright-colour);
}

#computerPhoneImage{
    max-width: 80%;
    margin: 3rem 0;
}

#computerImage{
    display: none;
    margin: 3rem 0;
}

#phoneImage{
    display: none;
    margin-bottom: 3rem;
}

.projectList { 
    display: inline-flex;
    flex-direction: column;
    justify-content: start;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    align-items: center;
    padding: 1rem 2rem;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
}

.projectSelection {
    width: 150px;
    height: 100px;
    display: inline-flex;
    align-items: center;
    padding: 0.5rem;
    box-sizing: border-box;
    justify-content: start;
    margin-top: 1rem;
    border-radius: 5px;
    background-color: var(--primary-colour);
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}

.projectSelection:hover {
    box-shadow: 0px 0px 15px 3px rgba(0,0,0,0.3);
    background-color: rgb(6, 83, 83);
    transition: all 0.5s;
}

.projectLogo {
    max-height: 60px;
    max-width: 8rem;
    object-fit: contain;
    object-position: center;
    position: relative;
    transition: all 0.2s;
}

.projectArrow {
    color: white;
    margin-right: 1rem;
    height: 3rem;
    width: 3rem;
    transition: all 0.2s ease-in-out;
    position: relative;
}

#projectsHoverMessage {
    position: absolute;
    background-color: white;
    padding: 0.5rem;
    color: var(--primary-colour);
    z-index: 4;
    border-radius: 5px 5px 5px 0 ;
    white-space: nowrap;
    display: inline;
}

@media only screen and (max-width: 850px) {
    #projects{
        flex-direction: column;
        align-items: start;
        justify-content: start;
    }

    .projectList{
        flex-direction: row;
        justify-content: space-around;
        padding: 0.5rem 0;
        width: 100%;
        height: 8rem;
        overflow-x: auto;
        overflow-y: hidden;
    }

    #projectListHeading{
        display: none;
    }

    .projectSelection{
        margin: 0 1px;
        height: 80px;
    }

    .projectArrow {
        transform: rotate(90deg);
    }

    #computerPhoneImage{
        max-width: 90%;
    }

}

@media only screen and (max-width: 720px) {
    .projectList {
        justify-content: space-between;
    }
    
    .projectSelection {
        margin: 0 0.2rem;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        max-width: 19%;
    }
    
    .projectArrow{
        display: none;
    }

    #computerPhoneImage{
        max-width: 100%;
    }

}

@media only screen and (max-width:500px) {
    .projectLogo {
        max-width: 90%;
    }

    #projectLinks {
        margin: 1rem 0;
    }

    .projectLink {
        margin: .5rem;
        text-align: center;
    }

    #computerPhoneImage{
        display: none;
    }

    #computerImage{
        display:inline;
        width: 100%;
    }

    #phoneImage{
        display: inline;
        width: 80%;
    }
}