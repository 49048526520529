
.contactIcon:hover{
    color:var(--bright-colour);
}

#contactBox{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem;
    overflow-y: auto;
}
#contactMethods{
    display: inline-flex;
    position: relative;
    top: 20dvh;
}

.contact{
    margin: 1rem 2rem;
    
}

.contact:hover{
    cursor: pointer;
}

.contactIcon {
    color: white;
    height: 4rem;
    width: 4rem;
    padding: 1rem;
    background-color: #ffffff20;
    border-radius: 50%;
    overflow: visible;
    transition: color 0.5s;
}

.contactIcon:hover {
    background-color: #ffffff40;
}

.contactLink {
    transition: color 0.5s;
}

@media only screen and (max-width: 500px){
    #contactMethods{
        flex-direction: column;
        top: 0;
    }

    .contact {
        margin-top: 2rem;
    }
}