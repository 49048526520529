ul {
    list-style-position: inside;
}

#skills{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem;
    overflow-y: auto;
}

#skills h2{
    margin-bottom: 3rem;
}

#skills p{
    width: 90%;
}

#skillBubblesBox {
    position: absolute;
    width: 98%;
    height: calc(100dvh - 8rem);
    box-sizing: border-box;
    z-index: 5;
    top: 7rem;
    pointer-events: none;
    overflow: hidden;
}



.skillBubble {
    position: absolute;
    top: 0;
    z-index: 5;
    pointer-events: auto;
    height: 2rem;
    width: 2rem;
    display: inline;
    object-fit: contain;
    background-color: #ffffff40;
    padding: 1rem;
    border-radius: 50%;
    overflow:visible;
}

#techSkills {
    text-align: left;
    width: 90%;
    box-sizing: border-box;
}

#techSkills h3{
    margin-top: 3rem;
    margin-bottom: 1rem;
    text-align: left;
    color: var(--bright-colour);
}

.skillSections{
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.skillsList {
    text-align: left;
    margin: 0 1rem;
    display: block;
    width: 16rem;
}

.skill {
    transition: all 0.5s;
}

.skill:hover{

    cursor:default;
}

