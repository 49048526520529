header {
    width: 100%;
    display: inline-flex;
    padding: 1rem 3rem;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}

#nav {
    display: inline-flex;
    justify-content: space-between;
    margin-left: 2rem;
}

.inactiveNavLink {
    font-family: "Lilita One", sans-serif;
    font-weight: 100;
    font-style: normal;
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
    margin-left: 2rem;
}

.inactiveNavLink:hover {
    font-weight: 600;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    color: #ffffff00;
    text-decoration: underline;
}

.activeNavLink {
    font-family: "Lilita One", sans-serif;
    font-weight: 100;
    font-style: normal;
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
    margin-left: 2rem;
    text-decoration: underline;
}

#name {
    font-size: 3rem;
    font-family: "Lilita One", sans-serif;
    padding: 0;
    margin: 0;
    line-height: 1;
    margin-bottom: 10px;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #ffffff;
    color: #ffffff00;
}

#name:hover{
    cursor: pointer;
}

#name span{
    font-size: 3rem;
    font-family: "Lilita One", sans-serif;
    padding: 0;
    margin: 0;
    line-height: 1;
    margin-bottom: 10px;
    -webkit-text-stroke-width: 1px;
    color: #ffffff00;
}

#title {
    color: white;
    font-size: 1rem;
    padding: 0;
    margin: 0;
}

@media only screen and (max-width: 700px) {
    header {
        flex-direction: column;
        align-items: center;
        padding-top: 1rem;
    }

    #nav {
        width: 100%;
        margin-left: 0;
    }

    .inactiveNavLink {
        margin-left: 0;
    }

    .activeNavLink {
        margin-left: 0;
    }

    #title {
        display: none;
    }

}



/* Navbar */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 4;
}

/* Burger Menu */
.navbar-toggler {
    display: none;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.navbar-toggler-icon::before {
    top: -10px;
}

.navbar-toggler-icon::after {
    top: 10px;
}

.expandMenu{
    color: white;
    height: 3rem;
    width: 3rem;
    transition: color 0.5s;
}

.expandMenu:hover{
    color: var(--bright-colour)
}

/* Navbar Links */
.navbar-collapse {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    transition: all 0.5s;
    position: relative;
    z-index: 3;
}

.navbar-nav {
    list-style: none;
    display: flex;
    gap: 20px;
    padding: 0;
    
}

.nav-item {
    display: block;
}


/* Mobile View */
@media (max-width: 950px) {
    .navbar-toggler {
        display: block;
    }

    #title{
        display: none;
    }

    .navbar-collapse {
        display: flex;
        flex-direction: column;
        width: 100dvw;
        background: var(--primary-colour);
        position: absolute;
        left: -3rem;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 1rem 0;
        z-index: 3;
        transition: top 0.5s;
    }

    .navbar-collapse.active {
        display: flex;
    }

    .navbar-nav {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
}

@media only screen and (max-width: 400px){
    header { 
        padding: 1rem;
    }

    .navbar-collapse{
        left: -1rem;
    }
}