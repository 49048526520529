#about {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#introBox {
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin: 1rem;

}

#intro {
    margin: 1rem;
    color: var(--light-colour);
    max-width: 80%;
    flex-shrink: 1;
}

#portraitBox{
    display: block;
    background-size: cover;
    margin: 0.5rem;
    overflow: hidden;
    background-color: #00000020;
    border-radius: 50%;
    flex: 0 0 360px;
}

.mePortrait{
    width: 360px;
    height: 360px;
    position: relative;
    transition: all 0.5s;
}

#hair {
    display: inline-flex;
    flex-direction: column;
}

#facialHair {
    display: inline-flex;
}

#eyesEars {
    top: -730px;
}

.introHighlight {
    font-weight: bold;
    color: var(--bright-colour);
}

#introTextFollowMouse {
    position: absolute;
    background-color: white;
    padding: 0.5rem;
    color: var(--primary-colour);
    z-index: 2;
    border-radius: 5px 5px 5px 0 ;
    white-space: nowrap;
}

#introImageFollowMouse {
    position: absolute;
    width: 80px;
    z-index: 3;
}

.activeHighlight {
    color: var(--inverse-colour);
}

.activeHighlight:hover {
    cursor: pointer;
}

@media only screen and (max-width: 750px) {
    #introBox {
        flex-direction: column-reverse;
        align-items: center;
    }

}

@media only screen and (max-width: 450px) {
    .mePortrait {
        width: 200px;
        height: 200px;
        
    }

    #portraitBox{
        height: 200px;
        flex: 0 0 200px;
    }

    #eyesEars {
        top: -410px;
    }
}